import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentManagerService } from '../document-manager.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-envelope-template',
  templateUrl: './envelope-template.component.html',
  styleUrl: './envelope-template.component.css'
})
export class EnvelopeTemplateComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  private previousUrl: string | null = null;
  frmEnvelopeTemplate: FormGroup;
  envelopeTypeData: any;

  constructor(
    private fb: FormBuilder,
    private documentService: DocumentManagerService,
    private toaster: ToasterService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loadEnvelopeTemplateTypes();
    this.commonService.checkQueryParameters(this.activatedRoute).then(decryptedParams => {
      if (decryptedParams) {
        const { envelopeTemplateId } = decryptedParams;
        this.frmEnvelopeTemplate.controls['envelopeTemplateId'].patchValue(envelopeTemplateId);
        this.GetEnvelopeTemplate();
        this.previousUrl = this.location.path(true);
      }
    }).catch(error => {

    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initForm(): void {
    this.frmEnvelopeTemplate = this.fb.group({
      envelopeTemplateId: [0],
      envelopeTemplateName: ['', Validators.required],
      typeId: ['', Validators.required]
    });
  }

  private loadEnvelopeTemplateTypes(): void {
    this.subscription.add(
      this.documentService.GetEnumEnvelopeTemplateTypes().subscribe({
        next: ({ payload }) => this.envelopeTypeData = payload,
        error: error => { }
      })
    );
  }

  private GetEnvelopeTemplate(): void {
    this.subscription.add(
      this.documentService.GetEnvelopeTemplate(this.frmEnvelopeTemplate.controls['envelopeTemplateId'].value).subscribe({
        next: ({ payload }) => this.frmEnvelopeTemplate.patchValue(payload),
        error: error => this.toaster.error(`${error}`)
      })
    );
  }

  addUpdateEnvelopeTemplate(): void {
    if (this.frmEnvelopeTemplate.invalid) {
      this.toaster.warning('Please add the required fields highlighted in red.');
      this.commonService.validateAllFormFields(this.frmEnvelopeTemplate);
      return;
    }

    this.subscription.add(
      this.documentService.AddUpdateEnvelopeTemplate(this.frmEnvelopeTemplate.value).subscribe({
        next: data => {
          this.resetForm();
          if (this.previousUrl) {
            this.location.back();
          }
        },
        error: error => this.toaster.error(`${error}`)
      })
    );
  }

  private resetForm(): void {
    this.initForm();
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.frmEnvelopeTemplate.get(controlName);
    return control.touched && control.invalid;
  }
}
