import * as i0 from '@angular/core';
import { Directive, EventEmitter, TemplateRef, Component, Input, Output, ContentChild, ViewChild, NgModule } from '@angular/core';
import { timer } from 'rxjs';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * fileEntry is an instance of {@link FileSystemFileEntry} or {@link FileSystemDirectoryEntry}.
 * Which one is it can be checked using {@link FileSystemEntry.isFile} or {@link FileSystemEntry.isDirectory}
 * properties of the given {@link FileSystemEntry}.
 */
const _c0 = ["fileSelector"];
function NgxFileDropComponent_ng_template_4_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r4.dropZoneLabel);
  }
}
function NgxFileDropComponent_ng_template_4_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "input", 9);
    i0.ɵɵlistener("click", function NgxFileDropComponent_ng_template_4_div_1_Template_input_click_1_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r6.openFileSelector($event));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵpropertyInterpolate("value", ctx_r5.browseBtnLabel);
    i0.ɵɵproperty("className", ctx_r5.browseBtnClassName);
  }
}
function NgxFileDropComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NgxFileDropComponent_ng_template_4_div_0_Template, 2, 1, "div", 6)(1, NgxFileDropComponent_ng_template_4_div_1_Template, 2, 2, "div", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.dropZoneLabel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.showBrowseBtn);
  }
}
function NgxFileDropComponent_ng_template_6_Template(rf, ctx) {}
const _c1 = a0 => ({
  openFileSelector: a0
});
class NgxFileDropEntry {
  constructor(relativePath, fileEntry) {
    this.relativePath = relativePath;
    this.fileEntry = fileEntry;
  }
}
class NgxFileDropContentTemplateDirective {
  constructor(template) {
    this.template = template;
  }
}
NgxFileDropContentTemplateDirective.ɵfac = function NgxFileDropContentTemplateDirective_Factory(t) {
  return new (t || NgxFileDropContentTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
NgxFileDropContentTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NgxFileDropContentTemplateDirective,
  selectors: [["", "ngx-file-drop-content-tmp", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxFileDropContentTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[ngx-file-drop-content-tmp]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, null);
})();
class NgxFileDropComponent {
  constructor(zone, renderer) {
    this.zone = zone;
    this.renderer = renderer;
    this.accept = '*';
    this.directory = false;
    this.multiple = true;
    this.dropZoneLabel = '';
    this.dropZoneClassName = 'ngx-file-drop__drop-zone';
    this.useDragEnter = false;
    this.contentClassName = 'ngx-file-drop__content';
    this.showBrowseBtn = false;
    this.browseBtnClassName = 'btn btn-primary btn-xs ngx-file-drop__browse-btn';
    this.browseBtnLabel = 'Browse files';
    this.onFileDrop = new EventEmitter();
    this.onFileOver = new EventEmitter();
    this.onFileLeave = new EventEmitter();
    this.isDraggingOverDropZone = false;
    this.globalDraggingInProgress = false;
    this.files = [];
    this.numOfActiveReadEntries = 0;
    this.helperFormEl = null;
    this.fileInputPlaceholderEl = null;
    this.dropEventTimerSubscription = null;
    this._disabled = false;
    this.openFileSelector = event => {
      if (this.fileSelector && this.fileSelector.nativeElement) {
        this.fileSelector.nativeElement.click();
      }
    };
    this.globalDragStartListener = this.renderer.listen('document', 'dragstart', evt => {
      this.globalDraggingInProgress = true;
    });
    this.globalDragEndListener = this.renderer.listen('document', 'dragend', evt => {
      this.globalDraggingInProgress = false;
    });
  }
  get disabled() {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = value != null && `${value}` !== 'false';
  }
  ngOnDestroy() {
    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
      this.dropEventTimerSubscription = null;
    }
    this.globalDragStartListener();
    this.globalDragEndListener();
    this.files = [];
    this.helperFormEl = null;
    this.fileInputPlaceholderEl = null;
  }
  onDragOver(event) {
    if (this.useDragEnter) {
      this.preventAndStop(event);
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy';
      }
    } else if (!this.isDropzoneDisabled() && !this.useDragEnter && event.dataTransfer) {
      if (!this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = true;
        this.onFileOver.emit(event);
      }
      this.preventAndStop(event);
      event.dataTransfer.dropEffect = 'copy';
    }
  }
  onDragEnter(event) {
    if (!this.isDropzoneDisabled() && this.useDragEnter) {
      if (!this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = true;
        this.onFileOver.emit(event);
      }
      this.preventAndStop(event);
    }
  }
  onDragLeave(event) {
    if (!this.isDropzoneDisabled()) {
      if (this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = false;
        this.onFileLeave.emit(event);
      }
      this.preventAndStop(event);
    }
  }
  dropFiles(event) {
    if (this.isDropzoneDisabled()) {
      return;
    }
    this.isDraggingOverDropZone = false;
    if (event.dataTransfer) {
      let items;
      if (event.dataTransfer.items) {
        items = event.dataTransfer.items;
      } else {
        items = event.dataTransfer.files;
      }
      this.preventAndStop(event);
      this.checkFiles(items);
    }
  }
  /**
   * Processes the change event of the file input and adds the given files.
   * @param Event event
   */
  uploadFiles(event) {
    if (this.isDropzoneDisabled()) {
      return;
    }
    if (event.target) {
      const items = event.target.files || [];
      this.checkFiles(items);
      this.resetFileInput();
    }
  }
  getFakeDropEntry(file) {
    const fakeFileEntry = {
      name: file.name,
      isDirectory: false,
      isFile: true,
      file: callback => callback(file)
    };
    return new NgxFileDropEntry(fakeFileEntry.name, fakeFileEntry);
  }
  checkFile(item) {
    if (!item) {
      return;
    }
    // if ("getAsFile" in item) {
    //   const file = item.getAsFile();
    //   if (file) {
    //     this.addToQueue(
    //       this.getFakeDropEntry(file)
    //     );
    //     return;
    //   }
    // }
    if ("webkitGetAsEntry" in item) {
      let entry = item.webkitGetAsEntry();
      if (entry) {
        if (entry.isFile) {
          const toUpload = new NgxFileDropEntry(entry.name, entry);
          this.addToQueue(toUpload);
        } else if (entry.isDirectory) {
          this.traverseFileTree(entry, entry.name);
        }
        return;
      }
    }
    this.addToQueue(this.getFakeDropEntry(item));
  }
  checkFiles(items) {
    for (let i = 0; i < items.length; i++) {
      this.checkFile(items[i]);
    }
    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
    }
    this.dropEventTimerSubscription = timer(200, 200).subscribe(() => {
      if (this.files.length > 0 && this.numOfActiveReadEntries === 0) {
        const files = this.files;
        this.files = [];
        this.onFileDrop.emit(files);
      }
    });
  }
  traverseFileTree(item, path) {
    if (item.isFile) {
      const toUpload = new NgxFileDropEntry(path, item);
      this.files.push(toUpload);
    } else {
      path = path + '/';
      const dirReader = item.createReader();
      let entries = [];
      const readEntries = () => {
        this.numOfActiveReadEntries++;
        dirReader.readEntries(result => {
          if (!result.length) {
            // add empty folders
            if (entries.length === 0) {
              const toUpload = new NgxFileDropEntry(path, item);
              this.zone.run(() => {
                this.addToQueue(toUpload);
              });
            } else {
              for (let i = 0; i < entries.length; i++) {
                this.zone.run(() => {
                  this.traverseFileTree(entries[i], path + entries[i].name);
                });
              }
            }
          } else {
            // continue with the reading
            entries = entries.concat(result);
            readEntries();
          }
          this.numOfActiveReadEntries--;
        });
      };
      readEntries();
    }
  }
  /**
   * Clears any added files from the file input element so the same file can subsequently be added multiple times.
   */
  resetFileInput() {
    if (this.fileSelector && this.fileSelector.nativeElement) {
      const fileInputEl = this.fileSelector.nativeElement;
      const fileInputContainerEl = fileInputEl.parentElement;
      const helperFormEl = this.getHelperFormElement();
      const fileInputPlaceholderEl = this.getFileInputPlaceholderElement();
      // Just a quick check so we do not mess up the DOM (will never happen though).
      if (fileInputContainerEl !== helperFormEl) {
        // Insert the form input placeholder in the DOM before the form input element.
        this.renderer.insertBefore(fileInputContainerEl, fileInputPlaceholderEl, fileInputEl);
        // Add the form input as child of the temporary form element, removing the form input from the DOM.
        this.renderer.appendChild(helperFormEl, fileInputEl);
        // Reset the form, thus clearing the input element of any files.
        helperFormEl.reset();
        // Add the file input back to the DOM in place of the file input placeholder element.
        this.renderer.insertBefore(fileInputContainerEl, fileInputEl, fileInputPlaceholderEl);
        // Remove the input placeholder from the DOM
        this.renderer.removeChild(fileInputContainerEl, fileInputPlaceholderEl);
      }
    }
  }
  /**
   * Get a cached HTML form element as a helper element to clear the file input element.
   */
  getHelperFormElement() {
    if (!this.helperFormEl) {
      this.helperFormEl = this.renderer.createElement('form');
    }
    return this.helperFormEl;
  }
  /**
   * Get a cached HTML div element to be used as placeholder for the file input element when clearing said element.
   */
  getFileInputPlaceholderElement() {
    if (!this.fileInputPlaceholderEl) {
      this.fileInputPlaceholderEl = this.renderer.createElement('div');
    }
    return this.fileInputPlaceholderEl;
  }
  isDropzoneDisabled() {
    return this.globalDraggingInProgress || this.disabled;
  }
  addToQueue(item) {
    this.files.push(item);
  }
  preventAndStop(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
NgxFileDropComponent.ɵfac = function NgxFileDropComponent_Factory(t) {
  return new (t || NgxFileDropComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2));
};
NgxFileDropComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxFileDropComponent,
  selectors: [["ngx-file-drop"]],
  contentQueries: function NgxFileDropComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, NgxFileDropContentTemplateDirective, 5, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentTemplate = _t.first);
    }
  },
  viewQuery: function NgxFileDropComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileSelector = _t.first);
    }
  },
  inputs: {
    accept: "accept",
    directory: "directory",
    multiple: "multiple",
    dropZoneLabel: "dropZoneLabel",
    dropZoneClassName: "dropZoneClassName",
    useDragEnter: "useDragEnter",
    contentClassName: "contentClassName",
    showBrowseBtn: "showBrowseBtn",
    browseBtnClassName: "browseBtnClassName",
    browseBtnLabel: "browseBtnLabel",
    disabled: "disabled"
  },
  outputs: {
    onFileDrop: "onFileDrop",
    onFileOver: "onFileOver",
    onFileLeave: "onFileLeave"
  },
  decls: 7,
  vars: 15,
  consts: [[3, "className", "drop", "dragover", "dragenter", "dragleave"], [3, "className"], ["type", "file", 1, "ngx-file-drop__file-input", 3, "accept", "multiple", "change"], ["fileSelector", ""], ["defaultContentTemplate", ""], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "ngx-file-drop__drop-zone-label", 4, "ngIf"], [4, "ngIf"], [1, "ngx-file-drop__drop-zone-label"], ["type", "button", 3, "className", "value", "click"]],
  template: function NgxFileDropComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵlistener("drop", function NgxFileDropComponent_Template_div_drop_0_listener($event) {
        return ctx.dropFiles($event);
      })("dragover", function NgxFileDropComponent_Template_div_dragover_0_listener($event) {
        return ctx.onDragOver($event);
      })("dragenter", function NgxFileDropComponent_Template_div_dragenter_0_listener($event) {
        return ctx.onDragEnter($event);
      })("dragleave", function NgxFileDropComponent_Template_div_dragleave_0_listener($event) {
        return ctx.onDragLeave($event);
      });
      i0.ɵɵelementStart(1, "div", 1)(2, "input", 2, 3);
      i0.ɵɵlistener("change", function NgxFileDropComponent_Template_input_change_2_listener($event) {
        return ctx.uploadFiles($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(4, NgxFileDropComponent_ng_template_4_Template, 2, 2, "ng-template", null, 4, i0.ɵɵtemplateRefExtractor)(6, NgxFileDropComponent_ng_template_6_Template, 0, 0, "ng-template", 5);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(5);
      i0.ɵɵclassProp("ngx-file-drop__drop-zone--over", ctx.isDraggingOverDropZone);
      i0.ɵɵproperty("className", ctx.dropZoneClassName);
      i0.ɵɵadvance();
      i0.ɵɵproperty("className", ctx.contentClassName);
      i0.ɵɵadvance();
      i0.ɵɵproperty("accept", ctx.accept)("multiple", ctx.multiple);
      i0.ɵɵattribute("directory", ctx.directory || undefined)("webkitdirectory", ctx.directory || undefined)("mozdirectory", ctx.directory || undefined)("msdirectory", ctx.directory || undefined)("odirectory", ctx.directory || undefined);
      i0.ɵɵadvance(4);
      i0.ɵɵproperty("ngTemplateOutlet", ctx.contentTemplate || _r2)("ngTemplateOutletContext", i0.ɵɵpureFunction1(13, _c1, ctx.openFileSelector));
    }
  },
  dependencies: [i1.NgIf, i1.NgTemplateOutlet],
  styles: [".ngx-file-drop__drop-zone[_ngcontent-%COMP%]{height:100px;margin:auto;border:2px dotted #0782d0;border-radius:30px}.ngx-file-drop__drop-zone--over[_ngcontent-%COMP%]{background-color:#93939380}.ngx-file-drop__content[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:center;height:100px;color:#0782d0}.ngx-file-drop__drop-zone-label[_ngcontent-%COMP%]{text-align:center}.ngx-file-drop__file-input[_ngcontent-%COMP%]{display:none}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxFileDropComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-file-drop',
      template: "<div [className]=\"dropZoneClassName\"\r\n     [class.ngx-file-drop__drop-zone--over]=\"isDraggingOverDropZone\"\r\n     (drop)=\"dropFiles($event)\"\r\n     (dragover)=\"onDragOver($event)\"\r\n     (dragenter)=\"onDragEnter($event)\"\r\n     (dragleave)=\"onDragLeave($event)\">\r\n  <div [className]=\"contentClassName\">\r\n    <input \r\n      type=\"file\" \r\n      #fileSelector \r\n      [accept]=\"accept\" \r\n      [attr.directory]=\"directory || undefined\" \r\n      [attr.webkitdirectory]=\"directory || undefined\"\r\n      [attr.mozdirectory]=\"directory || undefined\"\r\n      [attr.msdirectory]=\"directory || undefined\"\r\n      [attr.odirectory]=\"directory || undefined\"\r\n      [multiple]=\"multiple\"\r\n      (change)=\"uploadFiles($event)\" \r\n      class=\"ngx-file-drop__file-input\" \r\n    />\r\n\r\n    <ng-template #defaultContentTemplate>\r\n      <div *ngIf=\"dropZoneLabel\" class=\"ngx-file-drop__drop-zone-label\">{{dropZoneLabel}}</div>\r\n      <div *ngIf=\"showBrowseBtn\">\r\n        <input type=\"button\" [className]=\"browseBtnClassName\" value=\"{{browseBtnLabel}}\" (click)=\"openFileSelector($event)\" />\r\n      </div>\r\n    </ng-template>\r\n\r\n    <ng-template\r\n      [ngTemplateOutlet]=\"contentTemplate || defaultContentTemplate\"\r\n      [ngTemplateOutletContext]=\"{ openFileSelector: openFileSelector }\">\r\n    </ng-template>\r\n  </div>\r\n</div>\r\n",
      styles: [".ngx-file-drop__drop-zone{height:100px;margin:auto;border:2px dotted #0782d0;border-radius:30px}.ngx-file-drop__drop-zone--over{background-color:#93939380}.ngx-file-drop__content{display:flex;align-items:center;justify-content:center;height:100px;color:#0782d0}.ngx-file-drop__drop-zone-label{text-align:center}.ngx-file-drop__file-input{display:none}\n"]
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }];
  }, {
    accept: [{
      type: Input
    }],
    directory: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    dropZoneLabel: [{
      type: Input
    }],
    dropZoneClassName: [{
      type: Input
    }],
    useDragEnter: [{
      type: Input
    }],
    contentClassName: [{
      type: Input
    }],
    showBrowseBtn: [{
      type: Input
    }],
    browseBtnClassName: [{
      type: Input
    }],
    browseBtnLabel: [{
      type: Input
    }],
    onFileDrop: [{
      type: Output
    }],
    onFileOver: [{
      type: Output
    }],
    onFileLeave: [{
      type: Output
    }],
    contentTemplate: [{
      type: ContentChild,
      args: [NgxFileDropContentTemplateDirective, {
        read: TemplateRef
      }]
    }],
    fileSelector: [{
      type: ViewChild,
      args: ['fileSelector', {
        static: true
      }]
    }],
    disabled: [{
      type: Input
    }]
  });
})();
class NgxFileDropModule {}
NgxFileDropModule.ɵfac = function NgxFileDropModule_Factory(t) {
  return new (t || NgxFileDropModule)();
};
NgxFileDropModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxFileDropModule,
  bootstrap: [NgxFileDropComponent]
});
NgxFileDropModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxFileDropModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxFileDropComponent, NgxFileDropContentTemplateDirective],
      imports: [CommonModule],
      exports: [NgxFileDropComponent, NgxFileDropContentTemplateDirective],
      providers: [],
      bootstrap: [NgxFileDropComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxFileDropComponent, NgxFileDropContentTemplateDirective, NgxFileDropEntry, NgxFileDropModule };
