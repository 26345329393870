export enum EnumEnvelopeTemplateTypes {
    Email = 1,
    Static = 2,
    Dynamic = 3
}

export enum EnumMessageAction {
    Default = 1,
    Delete = 2,
    Update = 3
}