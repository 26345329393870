<form novalidate [formGroup]="frmEnvelopeTemplate">
  <div class="pg-container">
    <div class="card pb-5">
      <div class="card-body">
        <div class="row gx-2 align-items-center mb-3">
          <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer mt-1"
              [routerLink]="['/document-manager/envelope-template-list']">
              arrow_circle_left
            </span>
          </div>
          <div class="col-sm-auto">
            <label class="font-weight-semibold fs-20 mb-0">Create Envelope Template</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6 form-group">
                <label>Envelope Name</label>
                <input type="text" class="form-control" formControlName="envelopeTemplateName" maxlength="100">
                <div *ngIf="isControlInvalid('envelopeTemplateName')" class="validation-msg-label">
                  Required
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label>Type</label>
                <kendo-combobox class="w-100" formControlName="typeId" [textField]="'text'" [valuePrimitive]="true"
                  [valueField]="'value'" [data]="envelopeTypeData">
                </kendo-combobox>
                <div *ngIf="isControlInvalid('typeId')" class="validation-msg-label">
                  Required
                </div>
              </div>
            </div>
            <div class="row justify-content-end mt-3">
              <div class="col-md-auto">
                <button type="button" class="btn-primary btn" (click)="addUpdateEnvelopeTemplate()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>