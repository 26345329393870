import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DocumentManagerService } from '../document-manager.service';
import { ToasterService } from '@shared/utility/toaster.service';

@Component({
  selector: 'app-create-bundle',
  templateUrl: './create-bundle.component.html',
  styleUrl: './create-bundle.component.css'
})
export class CreateBundleComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  frmBundle: FormGroup;

  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private documentService: DocumentManagerService,
    private toaster: ToasterService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm() {
    this.frmBundle = this.fb.group({
      bundleName: ['', [Validators.required]]
    });
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.frmBundle.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  addBundle() {
    if (this.frmBundle.valid) {
      this.subscription.add(
        this.documentService.AddBundle(this.frmBundle.value).subscribe({
          next: ({ payload }) => {
            this.closeModal(payload);
          },
          error: error => { this.toaster.error(`${error}`); }
        })
      );
    }
  }

  closeModal(value = '') {
    this.activeModal.close(value);
  }

}
