import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { QueryParameters } from './commonHelper';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, map, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  encryptSecretKey = 'letalliance_1234';

  constructor(private router: Router) { }

  checkQueryParameters(activatedRoute: ActivatedRoute): Promise<QueryParameters> {
    return new Promise((resolve, reject) => {
      if (activatedRoute.snapshot?.queryParams['data']) {
        activatedRoute.queryParams.pipe(
          filter(params => !!params['data']),
          map(params => this.getQueryParam(params['data'])),
          take(1)
        ).subscribe(decryptparams => {
          if (!_.isNil(decryptparams)) {
            resolve(decryptparams);
          } else {
            reject('Decryption returned null or undefined');
          }
        }, err => reject(err));
      } else {
        resolve(null);
      }
    });
  }

  public getQueryParam(data): QueryParameters {
    const decryptparams: QueryParameters = this.decryptData(data);
    return decryptparams;
  }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
    }
  }

  decryptData(data) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (!control!.valid) {
        //console.log(field + " Is Invalid");
      }
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  async reload(url: string): Promise<boolean> {
    await this.router.navigateByUrl('/document-manager', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }
}
