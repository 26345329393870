<form novalidate [formGroup]="frmEnvelopSchedule">
    <div class="pg-container">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row align-items-center">
                            <div class="col-md-auto">
                                <span [routerLink]="['/document-manager/schedule-envelope-list']"
                                    class="material-symbols-outlined cursor-pointer d-flex">
                                    arrow_circle_left
                                </span>
                            </div>
                            <div class="col-sm-auto">
                                <div class="page-title-box p-0">
                                    <h4 class="page-title mb-0">Schedule Envelope</h4>
                                </div>
                            </div>
                            <!-- <div class="col-md-auto  ms-auto">
                                <div>
                                    <button class="btn-primary btn" (click)="createBundle()">Create
                                        Bundle</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label>Product Name</label>
                                    <kendo-combobox placeholder="Select Product" [data]="lstProduct"
                                        formControlName="productId" [textField]="'productName'" [valuePrimitive]="true"
                                        [valueField]="'productId'" class="w-100">
                                    </kendo-combobox>
                                    <div *ngIf="isControlInvalid('productId')" class="validation-msg-label">
                                        Required
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Select Event</label>
                                    <kendo-combobox eventId="Select Event" [data]="lstEvent" formControlName="eventId"
                                        [textField]="'text'" [valuePrimitive]="true" [valueField]="'value'"
                                        class="w-100">
                                    </kendo-combobox>
                                    <div *ngIf="isControlInvalid('eventId')" class="validation-msg-label">
                                        Required
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <div class="row">
                                        <div class="col-md position-relative">
                                            <label>Select Envelope</label>
                                            <kendo-combobox placeholder="Select Envelope" [data]="lstEnvelope"
                                                formControlName="envelopeId" [textField]="'envelopeTemplateName'"
                                                [valuePrimitive]="true" [valueField]="'envelopeId'" class="w-100">
                                            </kendo-combobox>
                                            <div *ngIf="isControlInvalid('envelopeId')" class="validation-msg-label">
                                                Required
                                            </div>
                                        </div>
                                        <div class="col-md-auto" style="margin-top: 25px;">
                                            <div>
                                                <button class="btn-outline-success btn"
                                                    (click)="addEnvelop()">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row input-without-lbl-gray">
                                <div class="col-md-6" *ngFor="let item of lstGroupEnvelope; let i = index">
                                    <div class="gray-input-box d-flex justify-content-between">
                                        {{ item.envelopeTemplateName }}
                                        <div>
                                            <span
                                                class="d-flex material-symbols-outlined text-danger cursor-pointer fs-22"
                                                (click)="deleteEnvelop(item.envelopeScheduleId)">
                                                delete
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>