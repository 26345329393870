<form novalidate [formGroup]="frmEnvelope">
    <div class="pg-container">
        <div class="card mb-0">
            <div class="card-body">
                <div class="row gx-2 align-items-center mb-3">
                    <div class="col-md-auto">
                        <span class="material-symbols-outlined cursor-pointer mt-1"
                            [routerLink]="['/document-manager/design-envelope-list']">
                            arrow_circle_left
                        </span>
                    </div>
                    <div class="col-md-auto">
                        <label class="font-weight-semibold fs-20 mb-0">Design Envelope</label>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-auto form-group">
                        <label>Select Envelope</label>
                        <kendo-combobox class="w-100" [data]="dataEnvelopeTemplate"
                            formControlName="envelopeTemplateName" [textField]="'envelopeTemplateName'"
                            [valuePrimitive]="true" [valueField]="'envelopeTemplateId'"
                            (valueChange)="valueChange($event)"></kendo-combobox>
                        <div *ngIf="isControlInvalid('envelopeTemplateName')" class="validation-msg-label">
                            Required
                        </div>
                    </div>
                    <div class="col-md-auto form-group">
                        <label>Version</label>
                        <input type="number" class="form-control" formControlName="version" min="0" step="0.1"
                            pattern="\d+(\.\d{0,1})?" />
                        <!-- <div *ngIf="isControlInvalid('version')" class="validation-msg-label">
                            <div *ngIf="frmEnvelope.get('version').errors?.required; else otherErrors">Required
                            </div>
                            <ng-template #otherErrors>
                                <div *ngIf="frmEnvelope.get('version').errors?.invalidIncrement">Value must be
                                    greater than the previous value</div>
                                <div *ngIf="frmEnvelope.get('version').errors?.invalidDecimal">Value must be
                                    incremented by only one decimal</div>
                            </ng-template>
                        </div> -->
                    </div>
                </div>
                <div class="row justify-content-end" *ngIf="showCreateTemplateButton()">
                    <div class="col-md-auto">
                        <button class="btn-primary btn" (click)="onCreateTemplate()">
                            Create Template
                        </button>
                    </div>
                </div>
                <div class="row justify-content-start"
                    *ngIf="formFlags.isTemplateFinalized && currentEnvelope?.typeId === enumEnvelopeTemplateTypes.Static">
                    <div class="col-md-12">
                        <!-- <input type="file" (change)="uploadDocument($event)" #file /> -->
                        <app-upload-envelope-document #uploadEnvelopeDocumentComponent [documentTypeId]="1"
                            (handleDocument)="handleDocument($event)" (handleDeleteDocument)="deleteDocument($event)"
                            [labelName]="'Please upload a Template Document'"
                            [currentDocument]="currentUploadedDocuments" [maximunDocument]="1">
                        </app-upload-envelope-document>
                    </div>
                </div>
                <div class="row justify-content-end mb-2" *ngIf="formFlags.isTemplateFinalized">
                    <div class="col-md position-relative"
                        *ngIf="currentEnvelope?.typeId === enumEnvelopeTemplateTypes.Email">
                        <h6>Subject Description</h6>
                        <input type="text" class="form-control" placeholder="Enter Subject" formControlName="subject" />
                        <div *ngIf="isControlInvalid('subject')" class="validation-msg-label">
                            Required
                        </div>
                    </div>
                    <div class="col-md-auto" style="margin-top: 26px">
                        <div class="d-flex gap-2">
                            <button class="btn-primary btn mr-2 position-relative input-doc-upload"
                                *ngIf="currentEnvelope?.typeId !== enumEnvelopeTemplateTypes.Static">
                                <input (change)="onChange($event)" #fileinput type="file" id="myFileInput"
                                    ng-controller="MyCtrl" />
                                Upload
                            </button>
                            <button class="btn-primary btn  mr-2" (click)="saveDesignedEnvelope()">
                                Save
                            </button>
                            <button class="btn-primary btn" (click)="openPdfModal()">
                                Preview
                            </button>
                            <button class="btn-primary btn" (click)="onCancelTemplate()">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div class="template-panel"
                    *ngIf="formFlags.isTemplateFinalized && currentEnvelope?.typeId !== enumEnvelopeTemplateTypes.Static">
                    <div class="left-panel">
                        <div class="search-box">
                            <input type="text" #inputBookMarkSearch class="form-control" placeholder="Search..." />
                        </div>
                        <div class="left-panel-overflow" *ngIf="dragContent?.length > 0">
                            <div *ngFor="let item of dragContent" id="{{ item?.bookMarkValue }}"
                                class="drag-class drag-zone d-flex justify-content-between" draggable="true">
                                <div kendoTooltip filter="span" class="d-flex align-items-center justify-content-between w-100" id="agglomerations">
                                    <span>
                                        {{ item?.bookMarkText }}
                                    </span>
                                    <span class="material-symbols-outlined text-primary cursor-pointer"
                                        title="{{ item?.bookMarkDesc }}">
                                        info
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-panel position-relative">
                        <div class="custom-template-editor">
                            <div id="froala-editor" [froalaEditor]="imgOptions"
                                [froalaModel]="frmEnvelope.get('content').value"
                                (froalaModelChange)="frmEnvelope.get('content').setValue($event)"></div>
                        </div>
                        <div *ngIf="isControlInvalid('content')" class="validation-msg-label"
                            style="top: 45px; z-index: 999;">
                            Required
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>