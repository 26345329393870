
export interface QueryParameters {
    envelopeTemplateId?: number;
    envelopeId?: number;
    productId?: number;
    eventId?: number;
}

export function checkFileSize(files: any, fileSize: any = 20000000) {
    let isFileSizeAllowed = false;
    for (let index = 0; index < files.length; index++) {
        if (files[index].size < fileSize) {
            isFileSizeAllowed = true;
            return isFileSizeAllowed;
        }
    }
    return isFileSizeAllowed;
}

export function checkFileType(files: any) {
    for (let index = 0; index < files.length; index++) {
        if (
            //files[index].name.toLowerCase().split(".").pop() === "jpeg" ||
            //files[index].name.toLowerCase().split(".").pop() === "jpg" ||
            //files[index].name.toLowerCase().split(".").pop() === "png" ||
            files[index].name.toLowerCase().split(".").pop() === "pdf"
            //files[index].name.toLowerCase().split(".").pop() === "msg" ||
            //files[index].name.toLowerCase().split(".").pop() === "doc" ||
            //files[index].name.toLowerCase().split(".").pop() === "docx" ||
            //files[index].name.toLowerCase().split(".").pop() === "xls" ||
            //files[index].name.toLowerCase().split(".").pop() === "xlsx"
        ) {
            return true;
        }
    }
    return false;
}

export function checkEnvelopeFileType(file: any) {
    const filesData = file.target.files;

    for (let index = 0; index < filesData.length; index++) {
        let name = filesData[index].name.toLowerCase().split(".").pop();
        if (name === "html") {
            return true;
        }
    }
    return false;
}

export function isFormDataEmpty(formData) {
    for (let pair of formData.entries()) {
        return false;
    }
    return true;
}