import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ToasterService } from '@shared/utility/toaster.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { checkFileSize, checkFileType } from '../commonHelper';
import { Subscription } from 'rxjs';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-upload-envelope-document',
  templateUrl: './upload-envelope-document.component.html',
  styleUrls: ['./upload-envelope-document.component.css']
})
export class UploadEnvelopeDocumentComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input() maximunDocument = 1;
  @Input() documentTypeId = 0;
  @Input() labelName = "Please upload document";
  @Input() currentDocument: any[] = [];

  @Output() handleDocument = new EventEmitter<any>();
  @Output() handleDeleteDocument = new EventEmitter<any>();

  @ViewChild('fileInput') fileInput!: ElementRef;

  fileCount = 0;
  fileName = '';
  uploadedFile!: SafeUrl;
  uploadFolderName = 'UploadedDocument/Optis/DocumentManager';
  lstAddedDocumentsList: any[] = [];
  isAnyDocument = true;
  public files: NgxFileDropEntry[] = [];

  constructor(
    private toasterService: ToasterService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getDocument();
  }

  fileOver(evt) {

  }

  fileLeave(evt) { }

  handleFiles(files: File[]): void {
    const checks = [
      { condition: () => this.lstAddedDocumentsList.length >= this.maximunDocument, action: () => this.toasterService.error(`You can upload only ${this.maximunDocument} document(s)`) },
      { condition: () => !files.length, action: () => this.fileCount = 0 },
      { condition: () => !checkFileSize(files), action: () => this.toasterService.warning('File with a max size of 20 MB is allowed!') },
      { condition: () => !checkFileType(files), action: () => this.toasterService.error('Unacceptable format selected') }
    ];

    for (const { condition, action } of checks) {
      if (condition()) {
        action();
        this.resetFileInput();
        return;
      }
    }

    const file = files[0];
    this.fileCount = files.length;
    this.fileName = file.name;
    this.uploadedFile = this.encodeImageFileAsURL(file);

    const formData = new FormData();
    formData.append('formFile', file, file.name);
    formData.append('keyPath', this.uploadFolderName);

    this.addDocument({ formData });
  }

  resetFileInput(): void {
    this.fileInput.nativeElement.value = '';
    this.fileName = '';
  }

  uploadFile(files: FileList): void {
    this.handleFiles(Array.from(files));
  }

  dropped(files: NgxFileDropEntry[]): void {
    Promise.all(files.map(file => new Promise<File>((resolve, reject) => {
      if (file.fileEntry.isFile) {
        (file.fileEntry as FileSystemFileEntry).file(resolve);
      } else {
        reject('Directory entries are not supported');
      }
    })))
      .then(files => this.handleFiles(files))
      .catch();
  }

  encodeImageFileAsURL(file: File): SafeUrl {
    const fileExtensionToIcon: { [key: string]: string } = {
      'doc': '../assets/images/word.svg',
      'docx': '../assets/images/word.svg',
      'pdf': '../assets/images/pdf.svg',
      'xls': '../assets/images/excel.svg',
      'xlsx': '../assets/images/excel.svg'
    };

    if (file.type.match(/image\/*/)) {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    }

    const extension = file.name.split('.').pop();
    return this.sanitizer.bypassSecurityTrustUrl(fileExtensionToIcon[extension] || '../assets/images/folder.svg');
  }

  async addDocument(event): Promise<void> {
    if (!this.fileName) {
      this.toasterService.warning('Please upload a file');
      return;
    }

    const newDocument = {
      name: this.fileName,
      documentTypeId: this.documentTypeId,
      uploadedFile: this.uploadedFile,
    };

    this.lstAddedDocumentsList.push(newDocument);
    this.isAnyDocument = true;
    this.fileName = '';
    this.handleDocument.emit({ ...event, addedDocument: newDocument });
  }

  getDocument(): void {
    this.subscription = this.messageService.receiveMessage().subscribe(message => {
      if (message) {
        this.lstAddedDocumentsList = message.map(element => ({
          ...element,
          name: `${element.path.split(/[\\/]/).pop()?.split('_')[0]}.${element.path.split('.').pop() || ''}`
        }));
        this.isAnyDocument = this.lstAddedDocumentsList.every(doc => doc.path !== '');
        this.messageService.clearMessage();
      }
    });
  }

  deleteDocument(item, index: number): void {
    if (index >= 0 && index < this.lstAddedDocumentsList.length) {
      this.lstAddedDocumentsList.splice(index, 1);
      this.handleDeleteDocument.emit({ formData: new FormData(), item });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
