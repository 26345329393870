<div class="row mt-2">
  <div class="col-md-12">
    <label>{{ labelName }}</label>
    <div class="row align-items-start">
      <div class="col-md-6 col-12">
        <div class="position-relative">
          <ngx-file-drop (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)"
            (onFileLeave)="fileLeave($event)" dropZoneLabel="Drag & Drop file to upload" dropZoneClassName="dropzone">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div class="imgNone d-flex flex-column text-center w-100">
                <div class="upload-info">
                  <i class="las la-cloud-upload-alt drop-zone-icon"></i>
                  <span class="drop-zone-text d-none d-md-block">Drag or click to upload</span>
                  <span class="drop-zone-text d-none d-md-block">Or</span>
                </div>
                <div class="file">
                  <input type="file" #fileinput class="file-input" placeholder="Browse File"
                    (change)="uploadFile(fileinput.files)" style="display: none;">
                  <button class="btn btn-cs btn-link" (click)="fileinput.click()">Browse File</button>
                  {{ fileName }}
                </div>
                <span class="drop-zone-text fs-12">File with a max size of 20 MB</span>
              </div>
            </ng-template>
          </ngx-file-drop>
        </div>
      </div>
      <div class="col-md-6 col-12" *ngIf="isAnyDocument">
        <div class="input-without-lbl-gray">
          <div class="gray-input-box d-flex justify-content-between"
            *ngFor="let item of lstAddedDocumentsList; let i = index">
            <label>
              <img src="../../../assets/images/pdf.svg" alt="PDF Icon" width="48" height="48">
              {{ item.name }}
            </label>
            <label>
              <span class="material-symbols-outlined cursor-pointer text-danger d-flex"
                (click)="deleteDocument(item, i)">
                delete
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>