import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentManagerService } from '../document-manager.service';
import { QueryParameters } from '../commonHelper';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { process } from "@progress/kendo-data-query";
import { DataBindingDirective } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-design-envelope-list',
  templateUrl: './design-envelope-list.component.html',
  styleUrl: './design-envelope-list.component.css'
})
export class DesignEnvelopeListComponent implements OnInit, OnDestroy {

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  private subscription = new Subscription();
  dataDesignedEnvelope = [];
  dataDesignedEnvelopeClone = [];

  constructor(
    private documentService: DocumentManagerService,
    private router: Router,
    private commonService: CommonService,
    private toaster: ToasterService
  ) {
  }

  ngOnInit(): void {
    this.GetEnvelopeTemplates();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private GetEnvelopeTemplates(): void {
    this.subscription.add(
      this.documentService.GetEnvelopeList().subscribe({
        next: ({ payload }) => {
          this.dataDesignedEnvelope = payload;
          this.dataDesignedEnvelopeClone = Object.assign([], this.dataDesignedEnvelope);
        },
        error: error => this.toaster.error(`${error}`)
      })
    );
  }

  onFilter(searchText) {
    this.dataDesignedEnvelope = searchText ? process(this.dataDesignedEnvelopeClone, {
      filter: {
        logic: 'or',
        filters: [
          { field: 'envelopeTemplateName', operator: 'contains', value: searchText },
          { field: 'version', operator: 'contains', value: searchText }
        ]
      }
    }).data : this.dataDesignedEnvelopeClone;

    if (searchText) {
      this.dataBinding.skip = 0;
    }
  }

  editDesignedEnvelope(envelopeId: number) {
    let encryptedqueryparams: QueryParameters = {
      envelopeId: envelopeId
    };
    this.router.navigate(['document-manager/design-envelope'], { queryParams: { data: this.commonService.encryptData(encryptedqueryparams) } });
  }

}
