import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentManagerService } from '../document-manager.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateBundleComponent } from '../create-bundle/create-bundle.component';
import { CommonService } from '../common.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-schedule-envelope',
  templateUrl: './schedule-envelope.component.html',
  styleUrls: ['./schedule-envelope.component.css']
})
export class ScheduleEnvelopeComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  frmEnvelopSchedule: FormGroup;
  lstProduct = [];
  lstEnvelope = [];
  lstGroupEnvelope: any;
  lstEvent = [];

  constructor(
    private fb: FormBuilder,
    private documentService: DocumentManagerService,
    private modalService: NgbModal,
    private commonService: CommonService,
    private toaster: ToasterService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.initForm();
    this.loadInitialData(['GetProductList', 'GetEnumEvents', 'GetEnvelopeListForScheduler']);
    this.setupValueChanges();

    this.commonService.checkQueryParameters(this.activatedRoute).then(decryptedParams => {
      if (decryptedParams) {
        this.frmEnvelopSchedule.patchValue(decryptedParams);
      }
    }).catch(error => {
      { this.toaster.error(`${error}`); }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.frmEnvelopSchedule = this.fb.group({
      productId: ['', Validators.required],
      envelopeId: ['', Validators.required],
      eventId: ['', Validators.required]
    });
  }

  private loadInitialData(methods: string[]) {
    methods.forEach(method => this[method]());
  }

  private setupValueChanges() {
    const controlNames = ['productId', 'eventId'];

    controlNames.forEach(controlName => {
      this.frmEnvelopSchedule.get(controlName).valueChanges.subscribe(value => {
        this.frmEnvelopSchedule.patchValue({ [controlName]: value }, { emitEvent: false });
        this.checkAndUpdate();
      });
    });
  }

  private checkAndUpdate(): void {
    const { productId, eventId } = this.frmEnvelopSchedule.value;
    if (productId && eventId) {
      this.GetEnvelopeScheduledById(productId, eventId);
    }
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.frmEnvelopSchedule.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  createBundle() {
    const modalRef = this.modalService.open(CreateBundleComponent, { size: 'sm', centered: true });
    modalRef.result.then(response => {
      if (response) {
        this.GetProductList();
        this.frmEnvelopSchedule.patchValue({ productId: response });
        this.checkAndUpdate();
      }
    });
  }

  private fetchData(serviceMethod: string, list: string) {
    this.subscription.add(
      this.documentService[serviceMethod]().subscribe({
        next: ({ payload }) => this[list] = payload,
        error: error => { this.toaster.error(`${error}`); }
      })
    );
  }

  GetProductList() {
    this.fetchData('GetProductList', 'lstProduct');
  }

  GetEnumEvents() {
    this.fetchData('GetEnumEvents', 'lstEvent');
  }

  GetEnvelopeListForScheduler() {
    this.fetchData('GetEnvelopeListForScheduler', 'lstEnvelope');
  }

  GetEnvelopeScheduledById(productId: string, eventId: string) {
    this.subscription.add(
      this.documentService.GetEnvelopeScheduledById(productId, eventId).subscribe({
        next: ({ payload }) => this.lstGroupEnvelope = payload?.scheduledEnvelopes,
        error: error => { this.toaster.error(`${error}`); }
      })
    );
  }

  addEnvelop() {
    if (this.frmEnvelopSchedule.invalid) return this.commonService.validateAllFormFields(this.frmEnvelopSchedule);

    const { envelopeId } = this.frmEnvelopSchedule.value;
    const currentEnvelope = this.lstGroupEnvelope.find((a: any) => a.envelopeId === envelopeId);
    if (currentEnvelope) return this.toaster.warning(`${currentEnvelope.envelopeTemplateName} envelope is already added.`);

    this.subscription.add(
      this.documentService.AddUpdateEnvelopeSchedule(this.frmEnvelopSchedule.value).subscribe({
        next: () => {
          this.frmEnvelopSchedule.patchValue({ envelopeId: '' });
          this.checkAndUpdate();
        },
        error: error => { this.toaster.error(`${error}`); }
      })
    );
  }

  deleteEnvelop(envelopeScheduleId: string) {
    const { productId, eventId } = this.frmEnvelopSchedule.value;
    this.subscription.add(
      this.documentService.DeleteEnvelopeSchedule(envelopeScheduleId).subscribe({
        next: () => this.GetEnvelopeScheduledById(productId, eventId),
        error: error => { this.toaster.error(`${error}`); }
      })
    );
  }

}