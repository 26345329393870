<div class="pg-container">
    <div class="card">
        <div class="card-body">
            <div class="row justify-content-between align-items-center mb-3">
                <div class="col-md-auto">
                    <div class="font-weight-semibold fs-20">Designed Envelope List</div>
                </div>
                <div class="col-md-auto col-auto">
                    <button type="button" class="btn btn-primary" [routerLink]="['/document-manager/design-envelope']">
                        Add
                    </button>
                </div>
            </div>
            <div class="mt-1">
                <div class="row">
                    <div class="col-md-12 kendo-custom-grid">
                        <kendo-grid [resizable]="true" #grid="kendoGrid" [pageable]="true" [pageSize]="10"
                            [sortable]="true" [navigable]="true" [data]="dataDesignedEnvelope"
                            [kendoGridBinding]="dataDesignedEnvelope">
                            <ng-template kendoGridToolbarTemplate>
                                <div class="row w-100">
                                    <div class="col-md m-top-10">
                                        <input placeholder="Search in all columns..." class="form-control w-100"
                                            (input)="onFilter($event.target.value)" />
                                    </div>
                                </div>
                            </ng-template>
                            <kendo-grid-column title="Envelope Template Name" field="envelopeTemplateName">
                            </kendo-grid-column>
                            <kendo-grid-column title="Version" field="version">
                            </kendo-grid-column>
                            <kendo-grid-column title="Action" [headerStyle]="{ width: '70px' }"
                                [style]="{ width: '70px' }">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="row m-0">
                                        <span class="material-symbols-outlined cursor-pointer"
                                            (click)="editDesignedEnvelope(dataItem.envelopeId)">
                                            edit_note
                                        </span>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>