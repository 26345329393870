<div class="pg-container">
    <div class="card">
        <div class="card-body">
            <div class="row justify-content-between align-items-center mb-3">
                <div class="col-md-auto">
                    <div class="font-weight-semibold fs-20 mb-0">Scheduled Envelope List</div>
                </div>
                <div class="col-md-auto col-auto">
                    <button type="button" class="btn btn-primary"
                        [routerLink]="['/document-manager/schedule-envelope']">
                        Add
                    </button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-12 kendo-custom-grid">
                    <kendo-grid [resizable]="true" #grid="kendoGrid" [data]="lstScheduledEnvelope"
                        [kendoGridBinding]="lstScheduledEnvelope" [pageable]="true" [pageSize]="10" [sortable]="true"
                        [navigable]="true">
                        <ng-template kendoGridToolbarTemplate>
                            <div class="row w-100">
                                <div class="col-md m-top-10">
                                    <input placeholder="Search in all columns..."
                                        (input)="onFilter($event.target.value)" class="form-control w-100" />
                                </div>
                            </div>
                        </ng-template>
                        <kendo-grid-column field="productName" title="Product Name">
                        </kendo-grid-column>
                        <kendo-grid-column field="eventName" title="Event Name">
                        </kendo-grid-column>
                        <kendo-grid-column field="envelopes" title="Envelope">
                        </kendo-grid-column>
                        <kendo-grid-column title="Action" [headerStyle]="{ width: '70px' }" [style]="{ width: '70px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="d-flex gap-2 m-0">
                                    <span class="material-symbols-outlined cursor-pointer"
                                        (click)="editEnvelop(dataItem.productId, dataItem.eventId)">
                                        edit_note
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>