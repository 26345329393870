import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DesignEnvelopeComponent } from './design-envelope/design-envelope.component';
import { DesignEnvelopeListComponent } from './design-envelope-list/design-envelope-list.component';
import { EnvelopeTemplateComponent } from './envelope-template/envelope-template.component';
import { EnvelopeTemplateListComponent } from './envelope-template-list/envelope-template-list.component';
import { ScheduleEnvelopeComponent } from './schedule-envelope/schedule-envelope.component';
import { ScheduleEnvelopeListComponent } from './schedule-envelope-list/schedule-envelope-list.component';
import { DocumentManagerDashboardComponent } from './document-manager-dashboard/document-manager-dashboard.component';

const routes: Routes = [
  { path: '', component: DocumentManagerDashboardComponent },
  { path: 'design-envelope', component: DesignEnvelopeComponent },
  { path: 'design-envelope-list', component: DesignEnvelopeListComponent },
  { path: 'envelope-template', component: EnvelopeTemplateComponent },
  { path: 'envelope-template-list', component: EnvelopeTemplateListComponent },
  { path: 'schedule-envelope', component: ScheduleEnvelopeComponent },
  { path: 'schedule-envelope-list', component: ScheduleEnvelopeListComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentManagerRoutingModule { }
