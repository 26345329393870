import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('documentMessage') || 'null'));

  sendMessage(message: any) {
    this.subject.next(message);
    localStorage.setItem('documentMessage', JSON.stringify(message));
  }

  receiveMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    localStorage.removeItem('documentMessage');
    this.subject.next(null);
  }
}
