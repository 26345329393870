import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentManagerService } from '../document-manager.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { QueryParameters } from '../commonHelper';
import { CommonService } from '../common.service';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { process } from "@progress/kendo-data-query";
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-envelope-template-list',
  templateUrl: './envelope-template-list.component.html',
  styleUrl: './envelope-template-list.component.css'
})
export class EnvelopeTemplateListComponent implements OnInit, OnDestroy {

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  private subscription = new Subscription();
  dataEnvelopeList: any = [];
  dataEnvelopeListclone: any = [];

  constructor(
    private documentService: DocumentManagerService,
    private router: Router,
    private commonService: CommonService,
    private confirmationDialogService: ConfirmationDialogService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.GetEnvelopeTemplateList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private GetEnvelopeTemplateList(): void {
    this.subscription.add(
      this.documentService.GetEnvelopeTemplateList().subscribe({
        next: ({ payload }) => {
          this.dataEnvelopeList = payload
          this.dataEnvelopeListclone = Object.assign([], this.dataEnvelopeList);
        },
        error: error => this.toaster.error(`${error}`)
      })
    );
  }

  onFilter(searchText) {
    this.dataEnvelopeList = searchText ? process(this.dataEnvelopeListclone, {
      filter: {
        logic: 'or',
        filters: [
          { field: 'envelopeTemplateName', operator: 'contains', value: searchText },
          { field: 'type', operator: 'contains', value: searchText }
        ]
      }
    }).data : this.dataEnvelopeListclone;

    if (searchText) {
      this.dataBinding.skip = 0;
    }
  }

  onClickEdit(dataItem) {
    let encryptedqueryparams: QueryParameters = {
      envelopeTemplateId: dataItem?.envelopeTemplateId
    };
    this.router.navigate(['document-manager/envelope-template'], { queryParams: { data: this.commonService.encryptData(encryptedqueryparams) } });
  }

  onClickDelete(dataItem) {
    this.confirmationDialogService.confirm("Alert", `Are you sure you want to delete ${dataItem?.envelopeTemplateName}`, "Yes", "No", "sm").then((r) => {
      if (r) {
        this.subscription.add(
          this.documentService.DeleteEnvelopeTemplate(dataItem?.envelopeTemplateId).subscribe({
            next: ({ payload }) => {
              this.dataEnvelopeList = payload
              this.dataEnvelopeListclone = Object.assign([], this.dataEnvelopeList);
            },
            error: error => this.toaster.error(`${error}`)
          })
        );
      }
    })
  }

}
