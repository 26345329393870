import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentManagerService } from '../document-manager.service';
import { CommonService } from '../common.service';
import { QueryParameters } from '../commonHelper';
import { Router } from '@angular/router';
import { ToasterService } from '@shared/utility/toaster.service';
import { process } from "@progress/kendo-data-query";
import { DataBindingDirective } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-schedule-envelope-list',
  templateUrl: './schedule-envelope-list.component.html',
  styleUrl: './schedule-envelope-list.component.css'
})
export class ScheduleEnvelopeListComponent implements OnInit, OnDestroy {

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  private subscription = new Subscription();
  lstScheduledEnvelope = [];
  lstScheduledEnvelopeClone = [];

  ngOnInit(): void {
    this.GetScheduledEnvelopes();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  constructor(private documentService: DocumentManagerService,
    private commonService: CommonService,
    private router: Router,
    private toaster: ToasterService) {
  }

  editEnvelop(productId, eventId) {
    let encryptedqueryparams: QueryParameters = {
      productId: productId,
      eventId: eventId
    };
    this.router.navigate(['document-manager/schedule-envelope'], { queryParams: { data: this.commonService.encryptData(encryptedqueryparams) } });
  }

  GetScheduledEnvelopes() {
    this.subscription.add(
      this.documentService.GetScheduledEnvelopes().subscribe({
        next: ({ payload }) => {
          this.lstScheduledEnvelope = payload;
          this.lstScheduledEnvelopeClone = Object.assign([], this.lstScheduledEnvelope);
        },
        error: error => { this.toaster.error(`${error}`); }
      })
    );
  }

  onFilter(searchText) {
    this.lstScheduledEnvelope = searchText ? process(this.lstScheduledEnvelopeClone, {
      filter: {
        logic: 'or',
        filters: [
          { field: 'eventName', operator: 'contains', value: searchText },
          { field: 'productName', operator: 'contains', value: searchText },
          { field: 'envelopes', operator: 'contains', value: searchText }
        ]
      }
    }).data : this.lstScheduledEnvelopeClone;

    if (searchText) {
      this.dataBinding.skip = 0;
    }
  }

}
