import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Subscription, filter, fromEvent, map } from 'rxjs';
import { DocumentManagerService } from '../document-manager.service';
import { environment } from '@env/environment';
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "froala-editor";
import { UploadEnvelopeDocumentComponent } from '../upload-envelope-document/upload-envelope-document.component';
import { EnumEnvelopeTemplateTypes } from '../enum';
import { CommonService } from '../common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { checkEnvelopeFileType, isFormDataEmpty } from '../commonHelper';
import { ToasterService } from '@shared/utility/toaster.service';
import { Location } from '@angular/common';
import { MessageService } from '../message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreviewEnvelopePopupComponent } from '../preview-envelope-popup/preview-envelope-popup.component';

@Component({
  selector: 'app-design-envelope',
  templateUrl: './design-envelope.component.html',
  styleUrl: './design-envelope.component.scss'
})
export class DesignEnvelopeComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  frmEnvelope: FormGroup;
  dataEnvelopeTemplate = [];
  dragContent = [];
  dragContentClone: any = [];
  isContentChanged: boolean = false;
  editor!: FroalaEditor;
  uploadEnvelopeDocumentComponent: UploadEnvelopeDocumentComponent;
  disallowedValues: any[] = [0, null, undefined];
  currentEnvelope: any;
  enumEnvelopeTemplateTypes = EnumEnvelopeTemplateTypes;

  @ViewChild("inputBookMarkSearch", { static: false })
  inputBookMarkSearch!: ElementRef;

  @ViewChild("fileinput")
  fileInputVariable!: ElementRef;

  formFlags = {
    isTemplateFinalized: false,
  };
  currentUploadedDocuments: any = [];
  formData = new FormData();

  previewFlags = {
    currentPreviewPath: '',
    typeId: 0
  }
  blobUrl: string;
  isLocaldocumentDeleted: boolean = false;

  constructor(private fb: FormBuilder,
    private documentService: DocumentManagerService,
    private commonService: CommonService,
    private router: Router,
    private toaster: ToasterService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private messageService: MessageService,
    private elementRef: ElementRef,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.initForm();
    this.commonService.checkQueryParameters(this.activatedRoute).then(decryptedParams => {
      if (decryptedParams) {
        const { envelopeId } = decryptedParams;
        this.GetEnvelopeTemplatesForEdit(envelopeId);
      }
      else {
        this.GetEnvelopeTemplates();
      }
    }).catch(error => {
      this.toaster.error(`${error}`);
    });
  }

  public imgOptions: Object = {
    angularIgnoreAttrs: [
      "style",
      "ng-reflect-froala-editor",
      "ng-reflect-froala-model",
    ],
    immediateAngularModelUpdate: true,
    key: environment.froalaKey,
    attribution: false,
    events: {
      initialized: (initControls) => {
        this.editor = initControls.getEditor();
        this.editor.events.on("drop", (dropEvent) => { });
      },
      contentChanged: () => {
        this.isContentChanged = true;
      },
      drop: (dropEvent) => {
        const { editor } = this;
        const { originalEvent } = dropEvent;

        editor.markers.insertAtPoint(originalEvent);
        editor.selection.restore();

        if (!editor.undo.canDo()) editor.undo.saveStep(null);

        editor.html.insert(originalEvent.dataTransfer.getData("html"));
        editor.undo.saveStep(null);

        dropEvent.preventDefault();
        dropEvent.stopPropagation();

        return false;
      },
    },
    enter: 'div',
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  uploadDocument(event) {
    const filesData = event.target.files;
  }

  dragCallback = e => e.dataTransfer.setData("html", this.dragContent.find(a => a.bookMarkValue == e.srcElement.id)?.bookMarkValue);

  GetBookMark() {
    this.subscription.add(
      this.documentService.GetBookMark().subscribe({
        next: ({ payload }) => {
          this.dragContent = payload;
          this.dragContentClone = Object.assign([], payload);
          this.updateDragContentListeners();
          this.setupSearchListener();
        },
        error: error => { this.toaster.error(`${error}`) }
      })
    );
  }

  updateDragContentListeners() {
    setTimeout(() => {
      this.elementRef.nativeElement
        .querySelectorAll(".drag-class")
        .forEach(element => {
          element.addEventListener("dragstart", this.dragCallback);
        });
    }, 1000);
  }

  setupSearchListener() {
    const searchInput = this.inputBookMarkSearch.nativeElement;
    fromEvent(searchInput, "keyup")
      .pipe(
        map((event: any) => event.target.value),
        filter(searchValue => searchValue || searchValue == '')
      )
      .subscribe(searchValue => {
        if (searchValue == '')
          this.dragContent = this.dragContentClone;
        const searchLowerCase = searchValue.toLowerCase();
        this.dragContent = this.dragContentClone.filter(
          a => a.bookMarkText.toString().toLowerCase().includes(searchLowerCase)
        );
        this.updateDragContentListeners();
      });
  }

  showCreateTemplateButton(): boolean {
    const envelopeTemplateName = this.frmEnvelope.get('envelopeTemplateName').value;
    return !this.disallowedValues.includes(envelopeTemplateName) && !this.formFlags.isTemplateFinalized;
  }

  initForm() {
    this.frmEnvelope = this.fb.group({
      envelopeId: [0],
      envelopeTemplateId: [0],
      envelopeTemplateName: [{ value: 0, disabled: false }, Validators.required],
      version: [{ value: 0, disabled: true }],
      subject: [null, Validators.required],
      content: ['', Validators.required],
      path: ['']
    });
  }

  private GetEnvelopeTemplates(): void {
    this.subscription.add(
      this.documentService.GetEnvelopeTemplates().subscribe({
        next: ({ payload }) => this.dataEnvelopeTemplate = payload,
        error: error => { this.toaster.error(`${error}`) }
      })
    );
  }

  private GetEnvelopeTemplatesForEdit(envelopeId): void {
    this.subscription.add(
      this.documentService.GetEnvelopeTemplatesForEdit().subscribe({
        next: ({ payload }) => {
          this.dataEnvelopeTemplate = payload;
          this.GetEnvelopeById(envelopeId);
        },
        error: error => { this.toaster.error(`${error}`) }
      })
    );
  }

  private updateEnvelopeControls(data: any): void {
    const frmEnvelopeControls = this.frmEnvelope.controls;
    const { envelopeTemplateId, path, subject, envelopeId, version } = data;

    if (this.currentEnvelope?.typeId === EnumEnvelopeTemplateTypes.Email) {
      frmEnvelopeControls.subject.patchValue(subject);
    }

    const patchCommonFields = () => {
      frmEnvelopeControls.envelopeTemplateName.patchValue(envelopeTemplateId);
      frmEnvelopeControls.envelopeId.patchValue(envelopeId);
      frmEnvelopeControls.version.patchValue(version);
      this.onCreateTemplate();
    };

    if (this.currentEnvelope?.typeId !== EnumEnvelopeTemplateTypes.Static) {
      this.loadTemplateData(path)
        .then((data: string) => {
          frmEnvelopeControls.content.setValue(data);
          patchCommonFields();
        })
        .catch();
    } else {
      patchCommonFields();
    }
  }

  private GetEnvelopeById(envelopeId): void {
    this.subscription.add(
      this.documentService.GetEnvelopeById(envelopeId).subscribe({
        next: ({ payload }) => {
          const { envelopeTemplateId, path, subject, version, typeId } = payload;
          if (typeId == EnumEnvelopeTemplateTypes.Static) this.getDocument(payload);
          this.previewFlags.currentPreviewPath = path;
          this.previewFlags.typeId = typeId;
          this.currentEnvelope = this.dataEnvelopeTemplate.find(item => item.envelopeTemplateId === envelopeTemplateId);
          this.updateEnvelopeControls({ envelopeTemplateId, path, subject, envelopeId, version });
        },
        error: (error) => { this.toaster.error(`${error}`) }
      })
    );
  }

  private getDocument(payload): void {
    this.currentUploadedDocuments.push(payload);
    this.messageService.sendMessage(this.currentUploadedDocuments);
  }

  private async loadTemplateData(templatePath: string): Promise<string> {
    try {
      return await this.documentService.DownloadTemplateAsString({ keyName: templatePath }).toPromise();
    } catch (error) {
      this.toaster.error(`${error}`);
      return '';
    }
  }

  validateIncrementValue(control: AbstractControl): ValidationErrors | null {
    // const previousValue = this.currectVersion;
    // const newValue = parseFloat(control.value);
    // if (newValue <= previousValue) {
    //   return { 'invalidIncrement': true };
    // }
    // if (newValue !== this.currectVersion + 0.1) {
    //   return { 'invalidDecimal': true };
    // }
    return null;
  }

  handleDocument({ addedDocument, formData }) {
    this.formData = formData;
    this.currentUploadedDocuments.push(addedDocument);
    this.isLocaldocumentDeleted = false;

    const file = Array.from(formData.values()).find(value => value instanceof File) as File | undefined;

    this.blobUrl = file ? window.URL.createObjectURL(file) : null;
  }

  valueChange(value: any) {
    this.currentEnvelope = this.dataEnvelopeTemplate.find(item => item.envelopeTemplateId === value);
    this.formFlags.isTemplateFinalized = false;
    this.previewFlags.typeId = this.currentEnvelope?.typeId;
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.frmEnvelope.get(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  onCreateTemplate() {
    this.formFlags.isTemplateFinalized = true;
    const envelopeType = this.currentEnvelope?.typeId;

    if (envelopeType != EnumEnvelopeTemplateTypes.Static) {
      this.GetBookMark();
    }

    this.frmEnvelope.get('envelopeTemplateName').disable();

    const subjectControl = this.frmEnvelope.controls['subject'];
    subjectControl.clearValidators();
    subjectControl.updateValueAndValidity();

    const setValidators = (controlName: string, validators: any[]) => {
      const control = this.frmEnvelope.controls[controlName];
      control.setValidators(validators);
      control.updateValueAndValidity();
    };

    const validationConfig: { [key: string]: { [controlName: string]: any[] } } = {
      [EnumEnvelopeTemplateTypes.Email]: {
        subject: [Validators.required],
        content: [Validators.required]
      },
      [EnumEnvelopeTemplateTypes.Static]: {
        content: []
      },
      [EnumEnvelopeTemplateTypes.Dynamic]: {
        content: [Validators.required]
      }
    };

    const config = validationConfig[envelopeType];
    if (!config) throw new Error('Invalid envelope type');

    for (const [controlName, validators] of Object.entries(config)) {
      setValidators(controlName, validators);
    }
  }

  saveDesignedEnvelope() {
    const isStatic = this.currentEnvelope.typeId === EnumEnvelopeTemplateTypes.Static;
    if (isStatic && isFormDataEmpty(this.formData) && this.isLocaldocumentDeleted) {
      this.toaster.error("Please ensure that you have uploaded a new document.");
      return;
    }
    const handleUploadResult = (result: any) => {
      this.AddUpdateEnvelope(result);
    };
    if (!this.frmEnvelope.valid) {
      this.commonService.validateAllFormFields(this.frmEnvelope);
      return;
    }
    const uploadObservable = isStatic
      ? this.documentService.UploadFileToS3(this.formData)
      : this.documentService.UploadCustomTemplateToS3(this.buildTemplateObject());
    this.subscription.add(uploadObservable.subscribe(handleUploadResult));
  }

  buildTemplateObject() {
    const { envelopeTemplateName, content } = this.frmEnvelope.controls;
    return {
      templateName: `Envelope-template_${this.currentEnvelope?.envelopeTemplateName}`,
      keyName: `Envelope-template/templates/${this.currentEnvelope?.envelopeTemplateName}_${Date.now()}`,
      recordId: envelopeTemplateName.value,
      templateHtml: btoa(unescape(encodeURIComponent(content.value)))
    };
  }

  AddUpdateEnvelope(data: any = '') {
    const { envelopeTemplateName, version, subject, envelopeId } = this.frmEnvelope.controls;
    const objSave = {
      envelopeId: envelopeId.value,
      envelopeTemplateId: envelopeTemplateName.value,
      version: version.value + 0.1,
      path: this.currentEnvelope.typeId == EnumEnvelopeTemplateTypes.Static ? data?.path : JSON.parse(data)?.path,
      subject: subject.value
    }

    this.subscription.add(
      this.documentService.AddUpdateEnvelope(objSave).subscribe({
        next: ({ payload }) => {
          this.onCancelTemplate();
        },
        error: error => { this.toaster.error(`${error}`) }
      })
    );
  }

  onCancelTemplate() {
    this.commonService.reload(this.router.url);
  }

  onChange(file) {
    if (checkEnvelopeFileType(file)) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.frmEnvelope.get('content').patchValue(fileReader.result);
      };
      fileReader.readAsText(file.target.files[0]);
    } else {
      this.toaster.error("Unacceptable format selected");
      this.fileInputVariable.nativeElement.value = "";
    }
  }


  deleteDocument(evt) {
    const { formData } = evt;
    this.isLocaldocumentDeleted = true;

    if (formData && !isFormDataEmpty(formData)) {
      this.subscription.add(
        this.documentService.UpdateEnvelopePath(evt?.item?.envelopeId).subscribe({
          next: () => this.handleDocumentDeletion(),
          error: error => { this.toaster.error(`${error}`) }
        })
      );
    }
  }

  private handleDocumentDeletion() {
    this.currentUploadedDocuments = [];
    this.messageService.sendMessage(this.currentUploadedDocuments);
  }

  openPdfModal() {
    const { typeId, currentPreviewPath } = this.previewFlags;

    const isValidStatic = typeId == EnumEnvelopeTemplateTypes.Static ? typeId === EnumEnvelopeTemplateTypes.Static && !this.isLocaldocumentDeleted : true;
    const isValidDynamic = this.frmEnvelope.get('content').valid;

    if (!isValidStatic || !isValidDynamic) {
      !isValidStatic ? this.toaster.error("Please upload the document to preview.") : this.toaster.error("Please add content for preview.");
      return;
    }

    const modalRef = this.modalService.open(PreviewEnvelopePopupComponent, { size: 'lg', centered: true });
    const instance = modalRef.componentInstance;

    instance.file = typeId === EnumEnvelopeTemplateTypes.Static
      ? (this.blobUrl || currentPreviewPath)
      : this.frmEnvelope.get('content').value;

    instance.typeId = typeId;
    instance.isUploaded = !this.blobUrl;
  }

}
