<div class="modal-header d-flex">
    <h4 class="modal-title">Preview</h4>
    <button type="button" class="btn fs-20 close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-container [ngSwitch]="typeId">
        <iframe *ngSwitchCase="enumEnvelopeTemplateTypes.Static" [src]="sanitizedPdfUrl" width="100%" height="500px"
            frameborder="0"></iframe>
        <div class="popup" *ngSwitchDefault>
            <div [froalaView]="finalTemplate"></div>
        </div>
    </ng-container>
</div>