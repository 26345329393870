<div class="modal-header d-flex">
    <h4 class="modal-title">Create Bundle</h4>
    <button type="button" class="close btn fs-20" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form novalidate [formGroup]="frmBundle">
        <div class="row">
            <div class="col">
                <input type="text" class="form-control" formControlName="bundleName">
                <div *ngIf="isControlInvalid('bundleName')" class="validation-msg-label">
                    Required
                </div>
            </div>
            <div class="col-auto">
                <button class="btn-primary btn  mr-2" (click)="addBundle()">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>