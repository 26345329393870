<div class="pg-container">
    <div class="card">
        <div class="card-body">
            <div class="row justify-content-start align-items-center mb-3">
                <div class="col-md-auto">
                    <div class="font-weight-semibold fs-20 mb-0">Envelope Template List</div>
                </div>
                <div class="col-md-auto ms-auto">
                    <div id="total-records" class="total-records">
                        Total Records: {{ dataEnvelopeList?.length ?? 0 }}
                    </div>
                </div>
                <div class="col-md-auto col-auto ">
                    <button type="button" class="btn btn-primary"
                        [routerLink]="['/document-manager/envelope-template']">
                        Add
                    </button>
                </div>
            </div>
            <div class="mt-1">
                <div class="row">
                    <div class="col-md-12 kendo-custom-grid">
                        <kendo-grid [resizable]="true" #grid="kendoGrid" [data]="dataEnvelopeList"
                            [kendoGridBinding]="dataEnvelopeList" [pageable]="true" [pageSize]="10" [sortable]="true"
                            [navigable]="true">
                            <ng-template kendoGridToolbarTemplate>
                                <div class="row w-100">
                                    <div class="col-md m-top-10">
                                        <input placeholder="Search in all columns..." class="form-control w-100"
                                            (input)="onFilter($event.target.value)" />
                                    </div>
                                </div>
                            </ng-template>
                            <kendo-grid-column title="Name" field="envelopeTemplateName">
                            </kendo-grid-column>
                            <kendo-grid-column title="Type" field="type">
                            </kendo-grid-column>
                            <kendo-grid-column title="Action" [width]="80">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex gap-2">
                                        <span class="material-symbols-outlined cursor-pointer" (click)="onClickEdit(dataItem)">
                                            edit_note
                                        </span>
                                        <span class="material-symbols-outlined cursor-pointer" (click)="onClickDelete(dataItem)">
                                            delete
                                        </span>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>