import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '@shared/async-services/data.service';
import { Observable, Subject, filter, map, take } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DocumentManagerService {

  public addOnDocumentManagerBasePath = "documentmanager/";
  public documentBasePath = "Document/";

  constructor(private dataService: DataService) { }

  public GetEnumEnvelopeTemplateTypes(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + 'GetEnumEnvelopeTemplateTypes'
    );
  }

  public GetEnumEvents(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + 'GetEnumEvents'
    );
  }

  public AddUpdateEnvelopeTemplate(object: any): Observable<any> {
    return this.dataService.post(
      this.addOnDocumentManagerBasePath + 'EnvelopeTemplate/AddUpdateEnvelopeTemplate', object
    );
  }

  public GetEnvelopeTemplateList(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + 'EnvelopeTemplate/GetEnvelopeTemplateList'
    );
  }

  public GetEnvelopeTemplate(envelopeTemplateId: number): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `EnvelopeTemplate/GetEnvelopeTemplate/${envelopeTemplateId}`
    );
  }

  public DeleteEnvelopeTemplate(envelopeTemplateId: number): Observable<any> {
    return this.dataService.delete(
      this.addOnDocumentManagerBasePath + `EnvelopeTemplate/DeleteEnvelopeTemplate/${envelopeTemplateId}`
    );
  }

  public GetEnvelopeTemplates(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `EnvelopeTemplate/GetEnvelopeTemplates`
    );
  }

  public GetEnvelopeTemplatesForEdit(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `EnvelopeTemplate/GetEnvelopeTemplatesForEdit`
    );
  }

  public UploadFileToS3(objFileInfo: any): Observable<any> {
    return this.dataService.post(
      this.documentBasePath + "Document/UploadDocument",
      objFileInfo
    );
  }

  public UploadCustomTemplateToS3(objSaveTemplate: any): Observable<any> {
    return this.dataService.postText(
      `${this.documentBasePath}Document/UploadCustomTemplateToS3`,
      objSaveTemplate
    );
  }

  public AddUpdateEnvelope(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnDocumentManagerBasePath + `Envelope/AddUpdateEnvelope`, objSave
    );
  }

  public GetEnvelopeList(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `Envelope/GetEnvelopeList`
    );
  }

  public GetEnvelopeById(envelopeId: number): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `Envelope/GetEnvelopeById/${envelopeId}`
    );
  }

  public DownloadTemplateAsString(objRequest: any): Observable<any> {
    return this.dataService.postText(
      `${this.documentBasePath}Document/DownloadTemplateAsString`,
      objRequest
    );
  }

  public DownloadFileFromS3(objFileInfo: any): Observable<any> {
    return this.dataService.postBlob(
      `$`, //remove path as this call not required and throw error if called by mistake {this.documentBasePath}Document/DownloadDocument
      objFileInfo
    );
  }

  public UpdateEnvelopePath(envelopeId): Observable<any> {
    return this.dataService.put(
      this.addOnDocumentManagerBasePath + `Envelope/UpdateEnvelopePath/${envelopeId}`, null
    );
  }

  public GetBookMark(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + 'BookMark/GetBookMark'
    );
  }

  public AddBundle(objSave): Observable<any> {
    return this.dataService.post(
      this.addOnDocumentManagerBasePath + `LstBundle/AddBundle`, objSave
    );
  }

  public GetBundleList(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `LstBundle/GetBundleList`
    );
  }

  public GetProductList(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `Product/GetProductList`
    );
  }

  public GetEnvelopeListForScheduler(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `Envelope/GetEnvelopeListForScheduler`
    );
  }

  public AddUpdateEnvelopeSchedule(objSave: any): Observable<any> {
    return this.dataService.post(
      this.addOnDocumentManagerBasePath + `EnvelopeSchedule/AddUpdateEnvelopeSchedule`, objSave
    );
  }

  public GetEnvelopeScheduledById(bundleId, eventId): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `EnvelopeSchedule/GetEnvelopeScheduledById/${bundleId}/${eventId}`
    );
  }

  public DeleteEnvelopeSchedule(envelopeScheduleId): Observable<any> {
    return this.dataService.delete(
      this.addOnDocumentManagerBasePath + `EnvelopeSchedule/DeleteEnvelopeSchedule/${envelopeScheduleId}`
    );
  }

  public GetScheduledEnvelopes(): Observable<any> {
    return this.dataService.get(
      this.addOnDocumentManagerBasePath + `EnvelopeSchedule/GetScheduledEnvelopes`
    );
  }

  public EnvelopePreview(content: string): Observable<any> {
    return this.dataService.postPreview(
      this.addOnDocumentManagerBasePath + 'EnvelopePreview', content, new HttpHeaders({ 'Content-Type': 'application/json' })
    );
  }

}
