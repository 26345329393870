import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentManagerRoutingModule } from './document-manager-routing.module';
import { DesignEnvelopeComponent } from './design-envelope/design-envelope.component';
import { DesignEnvelopeListComponent } from './design-envelope-list/design-envelope-list.component';
import { EnvelopeTemplateComponent } from './envelope-template/envelope-template.component';
import { EnvelopeTemplateListComponent } from './envelope-template-list/envelope-template-list.component';
import { ScheduleEnvelopeComponent } from './schedule-envelope/schedule-envelope.component';
import { ScheduleEnvelopeListComponent } from './schedule-envelope-list/schedule-envelope-list.component';
import { DocumentManagerService } from './document-manager.service';
import { SharedModule } from '@shared/shared.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { UploadEnvelopeDocumentComponent } from './upload-envelope-document/upload-envelope-document.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PreviewEnvelopePopupComponent } from './preview-envelope-popup/preview-envelope-popup.component';
import { CreateBundleComponent } from './create-bundle/create-bundle.component';

@NgModule({
  declarations: [
    DesignEnvelopeComponent,
    DesignEnvelopeListComponent,
    EnvelopeTemplateComponent,
    EnvelopeTemplateListComponent,
    ScheduleEnvelopeComponent,
    ScheduleEnvelopeListComponent,
    UploadEnvelopeDocumentComponent,
    PreviewEnvelopePopupComponent,
    CreateBundleComponent
  ],
  imports: [
    CommonModule,
    DocumentManagerRoutingModule,
    SharedModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    NgxFileDropModule,
    TooltipsModule
  ],
  providers: [
    DocumentManagerService
  ]
})
export class DocumentManagerModule { }
