import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentManagerService } from '../document-manager.service';
import { EnumEnvelopeTemplateTypes } from '../enum';
import { ToasterService } from '@shared/utility/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-preview-envelope-popup',
  templateUrl: './preview-envelope-popup.component.html',
  styleUrls: ['./preview-envelope-popup.component.css']
})
export class PreviewEnvelopePopupComponent implements OnInit, OnDestroy {

  @Input() typeId = 0;
  @Input() file = '';
  @Input() isUploaded = false;
  private subscription = new Subscription();
  sanitizedPdfUrl: SafeResourceUrl | undefined;
  enumEnvelopeTemplateTypes = EnumEnvelopeTemplateTypes;
  finalTemplate = '';

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private documentService: DocumentManagerService,
    private toaster: ToasterService
  ) { }

  ngOnInit() {
    if (this.typeId !== EnumEnvelopeTemplateTypes.Static) {
      this.previewTemplate();
    } else if (this.typeId === EnumEnvelopeTemplateTypes.Static && this.isUploaded) {
      this.subscription.add(
        this.documentService.DownloadFileFromS3({ keyName: this.file }).subscribe({
          next: (res) => {
            const url = window.URL.createObjectURL(res);
            this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          },
          error: (error) => { this.toaster.error(`${error}`); }
        })
      );
    } else {
      this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.file);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  previewTemplate() {
    const content = JSON.stringify(this.file);
    this.subscription.add(
      this.documentService.EnvelopePreview(content).subscribe({
        next: ({ payload }) => {
          this.finalTemplate = JSON.parse(payload);
        },
        error: (error) => { this.toaster.error(`${error}`); }
      })
    );
  }

  closeModal() {
    this.activeModal.close();
  }
}
